$datagrid-border: transparent;
$datagrid-border-color: transparent;
$datagrid-drag-header-border-color: transparent;
$datagrid-row-error-bg: transparent;
$datagrid-row-error-color: transparent;
$datagrid-base-color: #374151;
$datagrid-base-background-color: transparent;
$datagrid-focused-border-color: transparent;
$header-filter-color: transparent;
$header-filter-color-empty: transparent;
$base-focus-color: transparent;
$datagrid-text-stub-background-image-path: '';
@import '~devextreme/scss/widgets/base/dataGrid';

@apply flex flex-col;

> .dx-datagrid {
  @apply flex-1;
}

.dx-datagrid {
  @apply text-sm tracking-wide5 leading-tight;
}
.dx-datagrid .dx-datagrid-content-fixed .dx-datagrid-table .dx-col-fixed {
  @apply bg-white;
}
.dx-datagrid-headers {
  @apply z-10 border-b border-border text-primary;
}
.dx-datagrid-content .dx-datagrid-table .dx-row > td {
  @apply align-middle px-2 py-3;
}
.dx-datagrid-headers .dx-header-row.dx-row > td {
  @apply h-10 px-2 pt-1 pb-0;
}
.dx-datagrid-text-content {
  @apply w-full;
}
.dx-row-alt > td,
.dx-row-alt > tr > td {
  @apply bg-superlight;
}
.dx-datagrid-headers td.dx-datagrid-action {
  &:hover {
    @apply bg-gray_light text-primary_darker;
  }
  &:focus {
    @apply bg-gray_light;
  }
}
.dx-datagrid .dx-column-indicators {
  @apply align-middle h-4;
  &::before {
    // preload the icons
    font-family: 'DXIcons', sans-serif;
    content: '';
  }
}
.dx-data-row.dx-state-hover {
  @apply bg-gray_light;
}
.dx-row-lines {
  @apply border-b border-border;
}
.dx-datagrid-content .dx-datagrid-table .dx-row .dx-command-select {
  overflow: visible;
  width: 36px;
  min-width: 36px;
  max-width: 36px;
  padding-left: 12px;
  @screen sm {
    width: 48px;
    min-width: 48px;
    max-width: 48px;
    padding-left: 0;
  }

  .dx-checkbox {
    @apply focus:outline-none;
  }
}
.app-data-grid-pager {
  @apply z-10 border-t border-border flex items-stretch;
}
.dx-pager {
  @apply flex-1 flex-shrink-0 flex justify-end p-4;
}
.dx-pages {
  @apply flex items-center text-secondary_dark;

  .dx-info {
    @apply mr-3;
  }
  .dx-page-indexes {
    @apply flex items-center;
  }
  .dx-page {
    min-width: 1.5rem;
    min-height: 1.5rem;
    @apply ml-1 flex items-center justify-center px-2 rounded-full focus:outline-none;
    &:hover {
      @apply bg-gray_light;
    }
    &.dx-selection {
      @apply bg-primary text-white;
    }
  }
  .dx-separator {
    font-size: 0;
    @apply ml-1 px-2;
    &::after {
      content: '...';
      @apply text-sm;
    }
  }
  .dx-light-pages {
    @apply flex items-center;
    min-height: 2.25rem;

    .dx-page-index {
      @apply self-stretch mr-3;
    }

    @import '~devextreme/scss/widgets/base/textEditor';
    @import '~devextreme/scss/widgets/base/numberBox';
    .dx-texteditor {
      @apply border border-border rounded p-1;
      .dx-texteditor-input {
        @apply bg-transparent text-center;
      }

      &.dx-state-focused,
      &.dx-state-hover {
        @apply border-primary;
      }
    }
  }
}

.dx-navigate-button.dx-prev-button,
.dx-navigate-button.dx-next-button {
  @apply text-icon_dark;

  &::after {
    @apply w-5 h-5 block font-normal text-xl leading-none whitespace-nowrap;
    content: 'chevron_left';
    font-family: Material Icons;
  }

  &.dx-button-disable {
    @apply text-icon_dark_lighter;
  }
}

.dx-navigate-button.dx-prev-button {
  @apply mr-1;
  &::after {
    content: 'chevron_left';
  }
}

.dx-navigate-button.dx-next-button {
  @apply ml-1;
  &::after {
    content: 'chevron_right';
  }
}

.dx-page-index {
  width: 2.125rem !important;
}

.dx-datagrid-rowsview {
  .dx-row.dx-group-row:first-child {
    @apply border-none;
  }

  .dx-row.dx-group-row {
    @apply text-primary_800 bg-primary_50 border-none;

    .dx-command-expand {
      @apply w-8 relative;
    }

    td {
      @apply px-1 py-2 border-none;
    }
    &:not(:first-child) td {
      @apply border-t border-border;
    }
  }
}

.dx-datagrid-group-opened,
.dx-datagrid-group-closed {
  @apply absolute inset-0;
}

.dx-datagrid-group-opened::before {
  @apply inset-0 ml-auto my-auto block w-5 h-5;
  @apply font-normal text-xl leading-none whitespace-nowrap;

  font-family: Material Icons;
  content: 'chevron_right';
  @apply rotate-90;

  @apply text-primary_800;
}

.dx-datagrid-group-closed::before {
  @apply inset-0 ml-auto my-auto block w-5 h-5;
  @apply font-normal text-xl leading-none whitespace-nowrap;

  font-family: Material Icons;
  content: 'chevron_right';

  @apply text-primary_800;
}

.dx-datagrid-group-opened,
.dx-datagrid-group-closed {
  @apply w-full;
}
