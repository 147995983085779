@use '~devextreme/scss/widgets/base/icons';
@use '~devextreme/scss/widgets/base/ui';
@use '~devextreme/scss/widgets/base/overlay';
@use '~devextreme/scss/widgets/base/popup';
@use '~devextreme/scss/widgets/base/selectBox';
@use '~devextreme/scss/widgets/base/scrollView';

@include icons.dx-font-icons();

.dx-theme-marker {
  font-family: 'dx.app-custom';
}

.dx-overlay-content {
  @apply bg-white shadow-md-bottom rounded;
}
.dx-popup-content {
  padding: 0;
}

.dx-buttongroup-item.dx-button-mode-text {
  margin-left: 0;
}

.dx-scrollable-native.dx-scrollable-native-ios {
  .dx-scrollable-content {
    min-height: 100%;
  }
}

.app-data-grid {
  @import './dx.data-grid.scss';
}

.app-scheduler {
  @import './dx.scheduler.scss';
}

@import './dx.scrollable.scss';
