@tailwind base;
@tailwind components;
@tailwind utilities;

@import './dx';
@import './theme';

@import url('~pdfjs-dist/web/pdf_viewer.css');
@import './app/_shared/icons/streamline-icons.scss';

html {
  font-family: Roboto, sans-serif !important;
  width: 100%;
  height: 100vh;
}

body {
  width: 100%;
  height: 100%;
  @apply break-words;
}

@layer base {
  button {
    @apply focus:outline-none;
    &:disabled {
      @apply cursor-default;
    }
  }
  input {
    @apply focus:outline-none;
    &:disabled {
      @apply opacity-100;
    }
  }
  a {
    @apply outline-none;
  }
}

@layer utilities {
  .no-tap-highlight {
    -webkit-tap-highlight-color: transparent;
  }
}

.cdk-global-scrollblock {
  // https://github.com/angular/components/issues/25054
  overflow: hidden;
}

// Remove default outline applied by browser on keyboard focus
:focus-visible {
  outline: none;
}

.app {
  // Remove gap between form fields
  .mat-form-field-infix {
    border-top: 0;
  }

  // Remove default paddings from dialog
  .mat-dialog-container {
    padding: 0 !important;
    overflow: hidden !important;
  }

  // Adjust default button paddings
  .mat-flat-button,
  .mat-stroked-button,
  .mat-button-toggle-button {
    padding: 0 28px;
  }
  .mat-button-toggle-label-content {
    padding: 0 !important;
  }

  // Adjust button theme styling
  .mat-stroked-button {
    @apply border-border font-medium;
    &.mat-primary {
      @apply border-primary text-primary_darker;
    }
  }
  .mat-button-disabled {
    &.mat-stroked-button,
    &.mat-flat-button {
      @apply border-gray_dark;
      @apply bg-gray_dark;
      @apply text-placeholder;
    }
  }

  // Ensure mat toggle button fills the entire parent
  .mat-button-toggle-button {
    height: 100%;
  }

  .mat-focused .mat-form-field-required-marker {
    color: #bca68d;
  }

  // Make list denser
  .mat-list-base {
    padding-top: 0;
  }
  .mat-list-item {
    height: auto;
  }
  .mat-list-item-content {
    padding: 0;
  }
  .mat-list-text {
    padding-right: 0 !important;
  }

  // Disable arrow shift for mat-select
  .mat-select-arrow-wrapper {
    transform: none !important;
  }

  .mat-snack-bar-container {
    @screen sm {
      margin-top: 6.25rem;
    }
    background-color: theme('colors.primary_dark');
  }

  .mat-form-field {
    &.mat-form-field-disabled {
      .mat-form-field-label {
        @apply text-secondary_dark;
      }
      .mat-select-value-text,
      .mat-input-element {
        @apply text-placeholder;
      }
    }
    &.mat-focused {
      .mat-form-field-underline {
        @apply bg-primary;
      }
    }
    .mat-form-field-underline {
      @apply bg-border;
    }
  }

  .mat-input-element {
    @apply font-normal text-sm leading-tight tracking-wide5 text-primary_dark;
  }

  .mat-select-value-text {
    @apply font-normal text-sm leading-tight tracking-wide5 text-primary_dark;
  }

  .mat-form-field-label {
    @apply text-primary_dark font-normal text-base tracking-wide8 leading-tight;
  }
}

.app-loading-button {
  &.mat-flat-button {
    $indicator-color: (
      600: #ffffff,
      contrast: (
        600: #000000,
      ),
    );
    $indicator-palette: mat-palette($indicator-color, 600, A100);
    $indicator-theme: mat-light-theme(
      $indicator-palette,
      $indicator-palette,
      $indicator-palette
    );
    @include mat-progress-spinner-theme($indicator-theme);
  }

  &.mat-stroked-button {
    $indicator-color: (
      600: #bca68d,
      contrast: (
        600: #ffffff,
      ),
    );
    $indicator-palette: mat-palette($indicator-color, 600, A100);
    $indicator-theme: mat-light-theme(
      $indicator-palette,
      $indicator-palette,
      $indicator-palette
    );
    @include mat-progress-spinner-theme($indicator-theme);
  }

  .app-loading-button-spinner {
    @apply absolute inset-0 m-auto hidden;
  }

  &.app-loading-button--loading {
    .mat-button-wrapper {
      @apply invisible;
    }
    .app-loading-button-spinner {
      @apply block;
    }
  }
}

.app-dot-separator {
  @apply inline-block w-1 h-1 mx-1 rounded-full bg-icon_dark_lighter align-middle;
  font-size: 0;
}
