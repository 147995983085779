@use '~devextreme/scss/widgets/base/scrollable';

.dx-scrollbar-vertical .dx-scrollable-scroll {
  width: 12px;
}

.dx-scrollbar-horizontal .dx-scrollable-scroll {
  height: 12px;
}

.dx-scrollable-scroll {
  background-color: rgba(0, 0, 0, 0.25);
}
