@use '~devextreme/scss/widgets/base/scheduler' as base with (
  $scheduler-appointment-base-color: theme('colors.primary'),
  $scheduler-appointment-start-color: rgba(#000000, 0.4),
  $scheduler-base-border-color: #dbdbdb,
  $scheduler-accent-border-color: #dbdbdb,
  $scheduler-current-time-cell-color: theme('colors.primary_dark'),
  $scheduler-time-indicator-color: theme('colors.primary'),
  $scheduler-time-indicator-background-color: transparentize(#dacab7, 0.75),
  $scheduler-workspace-background-color: transparent,
  $scheduler-other-month-cell-opacity: 0.5,
  $scheduler-workspace-month-text-color: theme('colors.primary_dark'),
  $scheduler-workspace-accent-color: transparent,
  $scheduler-first-month-cell-background-color: transparent,
  $scheduler-panel-text-color: theme('colors.primary_dark'),
  $scheduler-left-column-width: 72px,
  $scheduler-workspace-active-cell-color: transparent,
  $scheduler-workspace-hovered-cell-color: theme('colors.primary_lighter'),
  $scheduler-workspace-focused-cell-color: transparent,
  $scheduler-droppable-cell-background-color: transparent,
  $scheduler-appointment-text-color: #ffffff,
  $scheduler-fill-focused-appointment: false,
  $scheduler-dd-appointment-hover-text-color: #ffffff,
  $scheduler-tooltip-appointment-text-color: theme('colors.primary_dark'),
  $scheduler-appointment-overlay-bg: #ffffff,
  $agenda-appointment-hover-bg: transparent,
  $agenda-appointment-active-bg: transparent,
  $agenda-appointment-text-color: theme('colors.primary_dark'),
  $agenda-appointment-title-font-size: 16px
);

& {
  @apply block overflow-hidden rounded border border-border;
  --app-time-panel-width: 72px;
  @screen sm {
    --app-time-panel-width: 92px;
  }
}

@include base.scheduler-mixin();

.dx-widget {
  @apply block;
}

.dx-scheduler-header {
  @apply hidden;
}

.dx-scheduler-time-panel {
  min-height: calc(var(--app-scheduler-height) - 40px);
}

.dx-scheduler-time-panel-cell {
  height: 24px;
  padding-right: 12px;
  @apply text-xs tracking-wide8 leading-tight;

  > div {
    margin-top: -10px;
  }

  &::after {
    content: '';
  }
}

.dx-scheduler-date-table {
  min-height: calc(var(--app-scheduler-height) - 40px);
}

.dx-scheduler-date-table-cell {
  height: 24px;
}

.dx-scheduler-work-space-week,
.dx-scheduler-work-space-work-week {
  .dx-scheduler-all-day-title {
    @apply text-sm tracking-wide5 leading-tight font-bold;
  }
  .dx-scheduler-all-day-title {
    height: 75px;
    line-height: 75px;
    top: 50px;
  }

  @include base.dx-scheduler-all-day-title-mixin(40px);

  .dx-scheduler-time-panel-row:nth-child(odd) .dx-scheduler-time-panel-cell {
    &::after {
      content: none;
    }
  }
  .dx-scheduler-time-panel-row:nth-child(even) .dx-scheduler-time-panel-cell {
    &::after {
      background-color: #dbdbdb;
    }
  }
}

.dx-scheduler-work-space {
  margin-top: -10px;
  margin-bottom: -1px;
  height: calc(100% + 10px);
  border: none;
}

.dx-scheduler-header-panel,
.dx-scheduler-time-panel {
  @apply text-sm tracking-wide5 leading-tight;
}

.dx-scheduler-header-panel {
  border-bottom: none !important;
}

.dx-scheduler-header-panel-cell {
  @apply border-transparent font-medium text-primary_darker;
}

.dx-scheduler-header-scrollable {
  @apply bg-superlight relative border-b border-border;
  &::before {
    @apply bg-superlight absolute;
    content: '';
    left: -100px;
    width: 100px;
    height: 100%;
  }
}

.dx-scheduler-header-panel-cell,
.dx-scheduler-date-table-cell {
  min-width: 110px;
}

.dx-scheduler-appointment {
  @apply rounded-sm;
  box-shadow: none !important;
}

.dx-scheduler-appointment-content {
  @apply text-sm tracking-wide5 leading-tight;

  &,
  .dx-scheduler-appointment-reduced & {
    padding: 4px 8px !important;
  }
}

.dx-scheduler-appointment-collector {
  box-shadow: none !important;
  font-size: 12px;

  &.dx-button {
    background-color: theme('colors.primary') !important;
  }
  .dx-scheduler-appointment-collector-content span:last-child {
    height: auto;
  }
}

@screen sm {
  .dx-scheduler-all-day-title {
    width: var(--app-time-panel-width);
  }
  .dx-scheduler-time-panel {
    width: var(--app-time-panel-width);
  }
  .dx-scheduler-time-panel-cell {
    padding-right: 16px;
    @apply text-sm tracking-wide5 leading-tight;
  }
  .dx-scheduler-work-space-both-scrollbar {
    .dx-scheduler-date-table-scrollable {
      margin-left: var(--app-time-panel-width);
    }
    .dx-scheduler-header-scrollable.dx-scrollable {
      margin-left: var(--app-time-panel-width);
    }
  }
}

.dx-scheduler-header-panel,
.dx-scheduler-date-table {
  width: auto !important;
  min-width: calc(var(--app-scheduler-width) - var(--app-time-panel-width));
}

.dx-scheduler-time-panel-cell.dx-scheduler-time-panel-current-time-cell:before {
  content: none;
}
